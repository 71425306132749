import $ from 'jquery';
import Evaporate from 'evaporate';
import sparkMD5 from 'spark-md5';
import sha256 from 'js-sha256';

window.$ = window.jQuery = require('jquery').noConflict();

var config = {
    signerUrl: window.AWS_SIGNER_URL,
    aws_key: window.AWS_S3_ACCESS_KEY_ID,
    bucket: window.AWS_S3_UPLOAD_PHOTOCONTEST_BUCKET,

    computeContentMd5: true,
    cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: sha256,
};

const uploader = Evaporate.create(config);

$(window).on('load', function () {
    // latest photo list sort by
    $('#id_sort_by').on('change', function (e) {
        e.preventDefault;
        const newLocation = window.location.pathname + '?sort_by=' + this.value;
        const title = 'latest photo by ' + this.value;
        // history.pushState(title, title, newLocation);
        window.location.href = newLocation;
        return false;
    });
    // Voting
    $('button.voting').on('click', function () {
        const $this = $(this);
        const $form = $(this).next();
        const $voted = $form.next();
        const $votedTextDescription = $voted.find('.description > .text');
        $.post($form.attr('action'), $form.serialize(), function (data) {
            const shareURL =
                window.location.protocol +
                '//' +
                window.location.host +
                data.item_url;
            var $voteShare = $voted.find('.description .share');
            var voteTitle;
            console.log('status', data.status);
            if (data.status === 'success' || data.status === 'already_voted') {
                if (data.status === 'success') {
                    if ($voted.data('show-percentage')) {
                        $votedTextDescription.html(
                            'Thank you for your vote. This item has ' +
                                Math.round(data.percentage) +
                                '% of the total votes.'
                        );
                    } else {
                        $votedTextDescription.html('Thank you for your vote.');
                    }
                } else {
                    $votedTextDescription.html(
                        'You already voted today. Vote again in 24 hours.'
                    );
                }
            }
            $this.hide();
            $voted.show();
        });
    });

    // Page padding
    // $('body').css('padding-top', $('header').outerHeight());

    // Lightbox
    $('.grid-photo').on('click', function () {
        $(this).next('.lightbox-BG').show();
    });
    $('.lightbox-content i.fa-times').on('click', function () {
        $(this).parent().parent().hide();
    });

    // FAQs
    $('.faq').on('click', function () {
        $(this).toggleClass('show');
        $(this)
            .children('.question')
            .children('i.fa')
            .toggleClass('fa-plus fa-minus');
    });

    // Respond to files selected using a file input field:
    const uploadElement = $('#upfile');
    const uploadLabel = $('.file-upload');
    const filenameElement = document.querySelector('#filename');
    const previewElement = $('#preview');
    const spinnerElement = $('.spinner');
    const imagePreview = $('#imagePreview');

    function uploadFile(file, filename) {
        uploader.then((evaporate) => {
            evaporate
                .add({
                    file: file,
                    name: filename,
                    contentType: file.type,
                    progress: (percent, stats) =>
                        console.log('Progress', percent, stats),
                    complete: (xhr, awsObjectKey) => {
                        spinnerElement.hide();
                    },
                    error: (mssg) => console.log('Error', mssg),
                    paused: () => console.log('Paused'),
                    pausing: () => console.log('Pausing'),
                    resumed: () => console.log('Resumed'),
                    cancelled: () => console.log('Cancelled'),
                    started: (fileKey) => {
                        previewElement.show();
                        uploadLabel.hide();
                    },
                    uploadInitiated: (s3Id) =>
                        console.log('Upload Initiated', s3Id),
                    warn: (mssg) => console.log('Warning', mssg),
                })
                .then(
                    (awsObjectKey) => {
                        console.log(
                            'File successfully uploaded to:',
                            awsObjectKey
                        );
                        // spinnerElement.hide()
                    },

                    (reason) =>
                        console.log('File did not upload sucessfully:', reason)
                );
        });
    }

    if (uploadElement) {
        uploadElement.on('change', (evt) => {
            let target = evt.target;
            filenameElement.value = target.files[0].name;
            uploadFile(target.files[0], target.dataset.filename);
            var reader = new FileReader();
            reader.onload = function (e) {
                imagePreview.attr('src', e.target.result);
                previewElement.show();
            };
            reader.readAsDataURL(target.files[0]); // convert to base64 string
        });
    }
});

$(document).ready(function () {
    // ===== Photo grid lightbox =====
    var nextPhoto, prevPhoto;
    var currentURL = window.location.href;

    $(document).on('click', '.lightbox-thumbnail', function (e) {
        e.preventDefault();

        var photoURL = $(this).attr('href');
        nextPhoto = $(this).next().attr('href');
        prevPhoto = $(this).prev().attr('href');

        $('.photo-grid .lightbox-BG').fadeIn();
        $('#lightboxContent').empty();

        $.ajax({
            url: photoURL,
            dataType: 'HTML',
            success: function (response) {
                // detach ad code so we can keep it across loads
                // var adHtml = $('#defaultPhotoContestTop').detach();
                $('#lightboxContent').load(
                    photoURL + ' #photoContents',
                    function () {
                        const photoId = $(this)
                            .find('.sidebar-ad')
                            .data('photo-id');
                        freestar.newAdSlots({
                            placementName: 'smithsonianmag_photocontest_new',
                            slotId:
                                'smithsonianmag_photocontest_new-' + photoId,
                        });
                        document.dispatchEvent(
                            new Event('photocontest:open-lightbox')
                        );
                    }
                );
                $(document).trigger('photocontest:open-lightbox', [photoURL]);
                window.history.pushState({}, null, photoURL);
            },
        });
    });

    $('.lightbox-BG *').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
    });

    $('.lightbox-BG, .lightbox-BG .fa-times').on('click', function () {
        $('.lightbox-BG').fadeOut();
        window.history.pushState({}, null, currentURL);
        document.dispatchEvent(new Event('photocontest:close-lightbox'));
    });

    // Show next/prev photo in lightbox
    $(document).on('click', '.fa-chevron-left', function (e) {
        e.stopPropagation();
        e.preventDefault();

        // Need to redefine next and previous photo
        $('#lightboxContent').html();

        $.ajax({
            url: prevPhoto,
            dataType: 'HTML',
            success: function (response) {
                $('#lightboxContent').load(prevPhoto + ' #photoContents');
            },
        });
    });

    $(document).on('click', '.fa-chevron-right', function (e) {
        e.stopPropagation();
        e.preventDefault();

        // Need to redefine next and previous photo

        $('#lightboxContent').html();

        $.ajax({
            url: nextPhoto,
            dataType: 'HTML',
            success: function (response) {
                $('#lightboxContent').load(nextPhoto + ' #photoContents');
            },
        });
    });

    // Photo grid infinite scroll
    var currentPage = 1;
    var nextPage = 2;

    if ($('#loaderGif').length) {
        $(window).on(
            'scroll',
            _.debounce(function () {
                var hT = $('#loaderGif').offset().top,
                    hH = $('#loaderGif').outerHeight() - 1000,
                    wH = $(window).height(),
                    wS = $(this).scrollTop(),
                    nextURL;

                if (window.location.href.indexOf('?') > -1) {
                    nextURL =
                        window.location.href.split('&page')[0] +
                        '&page=' +
                        nextPage;
                } else {
                    nextURL =
                        window.location.href.split('?')[0] +
                        '?page=' +
                        nextPage;
                }

                if (wS > hT - wH) {
                    $.ajax({
                        type: 'GET',
                        url: nextURL,
                        dataType: 'HTML',
                        success: function (response) {
                            $(
                                '<div id="photoContainer' +
                                    nextPage +
                                    '" class="photo-container"></div>'
                            ).appendTo('#photoGridPhotos');
                            $('#photoGridPhotos').attr(
                                'style',
                                'height: auto; position: relative;'
                            );
                            $('#photoContainer' + nextPage).load(
                                nextURL + ' #photoContainer1 > *'
                            );

                            $(
                                '#loaderGif, footer, #photoContainer' + nextPage
                            ).css('opacity', 0);
                        },
                        complete: function () {
                            setTimeout(function () {
                                $(
                                    '#loaderGif, footer, #photoContainer' +
                                        nextPage
                                ).animate(
                                    {
                                        opacity: 1,
                                    },
                                    500
                                );

                                // Add page number
                                $('.photo-container').each(function (index) {
                                    $(this)
                                        .children('.page-number')
                                        .html(
                                            '<span>Page ' +
                                                (index + 1) +
                                                '</span>'
                                        );
                                });

                                nextPage++;
                                currentPage++;
                                setTimeout(function () {
                                    document.dispatchEvent(
                                        new CustomEvent(
                                            'photocontest:pagination',
                                            { detail: currentPage }
                                        )
                                    );
                                }, 1000);
                            }, 100);
                        },
                        error: function (response) {
                            console.log('error loading photos');
                            $('#loaderGif').hide();
                            if (currentPage === 1) {
                                $('.page-number').hide();
                            }
                        },
                    });
                }
            }, 500)
        );
    }

    // ===== Edit profile form adjustments =====

    // Only display state field if US selected as country
    var selectedCountry = $('select#id_country option:selected')
        .text()
        .replace(/ /g, '');

    $('label[for="id_us_state"], select#id_us_state').hide(); // Edit profile form
    $('input#id_state').parent().hide(); // Photo submission form

    if (selectedCountry === 'UnitedStatesofAmerica') {
        $('label[for="id_us_state"], select#id_us_state').show(); // Edit profile form
        $('input#id_state').parent().show(); // Photo submission form
    }

    $('select#id_country').on('change', function () {
        var selectedCountry = $('select#id_country option:selected')
            .text()
            .replace(/ /g, '');

        if (selectedCountry === 'UnitedStatesofAmerica') {
            $('label[for="id_us_state"], select#id_us_state').show(); // Edit profile form
            $('input#id_state').parent().show(); // Photo submission form
        } else {
            $('label[for="id_us_state"], select#id_us_state').hide(); // Edit profile form
            $('input#id_state').parent().hide(); // Photo submission form
        }
    });

    // Realign inputs
    $('input#id_email_updates').prependTo('label[for="id_email_updates"]');
    $('input#id_special_offers').prependTo('label[for="id_special_offers"]');

    // ===== Photo submission form adjustments =====

    // Display second category only when first it selected
    $('#id_secondary_category, #id_tertiary_category').prop('disabled', true);

    $('#id_primary_category').on('change', function () {
        $('#id_secondary_category').prop('disabled', false);

        var primaryCategory = $('#id_primary_category option:selected').text();

        $(
            'select#id_secondary_category option:contains(' +
                primaryCategory +
                ')'
        ).remove();
        $(
            'select#id_tertiary_category option:contains(' +
                primaryCategory +
                ')'
        ).remove();
    });

    $('#id_secondary_category').on('change', function () {
        $('#id_tertiary_category').prop('disabled', false);

        var secondaryCategory = $(
            '#id_secondary_category option:selected'
        ).text();

        $(
            'select#id_tertiary_category option:contains(' +
                secondaryCategory +
                ')'
        ).remove();
    });

    // Mobile menu
    $('.header-content .fa-bars').on('click', function () {
        $('header nav').animate({ 'margin-top': 0 });
        $(this).hide();
        $('.header-content .fa-times').show();
    });
    $('.header-content .fa-times').on('click', function () {
        $('header nav').animate({ 'margin-top': '-200vh' });
        $(this).hide();
        $('.header-content .fa-bars').show();
    });
});

// Update billboard image for local testing
// document.addEventListener('DOMContentLoaded', () => {
//     if (
//         window.location.href.indexOf('localhost') > -1 &&
//         document.querySelector('.billboard')
//     ) {
//         // Select the element with class "billboard"
//         const billboard = document.querySelector('.billboard');

//         // Find the anchor tag within the billboard
//         const anchor = billboard.querySelector('a');

//         // Locate the image inside the anchor tag
//         const image = anchor.querySelector('img');

//         // Update the image source
//         image.src =
//             'https://tf-cmsv2-photocontest-media.s3.amazonaws.com/photocontest/billboard/Smithmag-PhotoContest21-WebsiteBanner-1428x645-notext.jpg';
//     }
// });
